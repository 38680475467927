import { AuthService } from '../../shared/services/auth.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriptionLike } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public imageURL = '';
  public dropdown: {
    header: {
      name: string;
      link?: string;
      subheader?: { name: string; subname: string; paragraph: string; link: string }[];
    }[];
  } = {
    header: [
      {
        name: 'Revision Packages',
        link: '/subjects/leaving-certificate',
        subheader: [
          {
            name: 'Junior Cycle',
            subname: 'Ideal for 1st, 2nd, 3rd year students.',
            paragraph: 'Access videos tutorials quizzes and notes for the whole course curriculum.',
            link: '/subjects/junior-cycle',
          },
          {
            name: 'Leaving Certificate',
            subname: 'Ideal for 4th, 5th, 6th year students.',
            paragraph: 'Access videos tutorials quizzes and notes for the whole course curriculum.',
            link: '/subjects/leaving-certificate',
          },
          // Add more subheaders as needed
        ],
      },
      {
        name: 'Live Courses',
        link: '/revision-courses',
      },
      { name: 'Pricing', link: '/pricing' },
      { name: 'Teachers & Schools', link: '/teachers' },
      // { name: 'Free Grinds', link: '/free-grinds' },
    ],
  };

  public fixedPosition: boolean = window.pageYOffset > 106 || window.innerWidth < 520;
  public logoWidth: string = this.fixedPosition ? '200px' : '400px';
  public isLoggedIn = false;
  private subscription?: SubscriptionLike;

  constructor(public route: Router, public authService: AuthService) {}

  ngOnInit() {
    window.addEventListener('scroll', this.checkIfShouldFix, true);
    window.addEventListener('resize', this.checkIfShouldFix, true);

    this.subscription = this.authService.loggedInStatus.subscribe((loggedIn) => {
      this.isLoggedIn = loggedIn;
    });
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.checkIfShouldFix, true);
    window.removeEventListener('resize', this.checkIfShouldFix, true);
    this.subscription?.unsubscribe();
  }

  checkIfShouldFix = (): void => {
    this.fixedPosition = window.pageYOffset > 106 || window.innerWidth < 520;
  };
}
