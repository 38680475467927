import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
})
export class DropdownMenuComponent implements OnInit, OnChanges {
  isRegisterOrLogin = false;
  isLogin = false;

  @Input() isLoggedIn?: boolean;
  @Input() imageURL!: string;
  @Input() dropdown!: {
    header: {
      name: string;
      link?: string;
      show?: boolean;
      subheader?: { name: string; link: string; subname: string; paragraph: string }[];
    }[];
  };
  public dropdownInUse!: {
    header: {
      name: string;
      link?: string;
      show?: boolean;
      isActivePage?: boolean;
      subheader?: {
        name: string;
        link: string;
        subname: string;
        paragraph: string;
      }[];
    }[];
  };
  public loggedInDropdown: {
    header: {
      name: string;
      link?: string;
      show?: boolean;
      subheader?: { name: string; link: string; subname: string; paragraph: string }[];
    }[];
  } = {
    header: [
      {
        name: 'My Portal',
        link: '/account',
        show: false,
        subheader: [],
      },
      {
        name: 'Live Courses',
        link: '/revision-courses',
        show: false,
        subheader: [],
      },
      {
        name: 'Logout',
        link: '/logout',
        show: false,
        subheader: [],
      },
    ],
  };
  public MenuOpen: boolean = false;
  public burgerMenuOpen: boolean = false;
  public menuInteractionStarted: boolean = false;
  public switchPageParams: any;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.isRegisterOrLogin =
        window.location.href.indexOf('register') !== -1 || window.location.href.indexOf('login') !== -1;
      this.isLogin = window.location.href.indexOf('login') !== -1;
      this.dropdownInUse.header.forEach((headerItem) => {
        if (headerItem.link) {
          headerItem.isActivePage = window.location.href.indexOf(headerItem.link) !== -1;
        }
      });
      const queryParamsOnPage = this.activatedRoute.snapshot.queryParams;
      if (queryParamsOnPage) {
        this.switchPageParams = queryParamsOnPage;
      }
    });
  }

  ngOnChanges() {
    this.dropdownInUse = this.isLoggedIn ? this.loggedInDropdown : this.dropdown;
  }

  toggleMenu() {
    this.menuInteractionStarted = true;
    this.burgerMenuOpen = !this.burgerMenuOpen;
    this.MenuOpen = !this.MenuOpen;
  }

  show(index: number) {
    this.dropdownInUse.header[index].show = !this.dropdownInUse.header[index].show;
  }

  closeBurger(header: any) {
    if (header.subheader && header.subheader.length) {
      //Keep burger open if there is a sub menu to show
    } else {
      this.burgerMenuOpen = false;
    }
  }
}
