import { Component } from '@angular/core';
import { ReviewsService } from 'src/app/shared/services/reviews.service';

@Component({
  selector: 'app-reviews-button',
  templateUrl: './reviews-button.component.html',
  styleUrls: ['./reviews-button.component.scss'],
})
export class ReviewsButtonComponent {
  public reviewsCount: number = 0;
  public reviewsScore: number = 0;

  constructor(private reviewService: ReviewsService) {
    this.reviewService.getReviewsCount().subscribe((count) => {
      this.reviewsCount = count;
    });
    this.reviewService.getReviewsScore().subscribe((score) => {
      this.reviewsScore = score;
    });
  }

  openReviews() {
    window.open('https://goo.gl/maps/nY8NVQMYNZXKq7WZA', '_blank')?.focus();
  }
}
