import { VerifiedAsLoggedInService } from './shared/services/verified-as-logged-in.service';
import { HeaderModule } from './components/header/header.module';
import { AuthService } from './shared/services/auth.service';
import { CreateDigitalInterceptorService } from './shared/interceptors/create-digital-interceptor.service';
import { HttpInterceptorService } from './shared/interceptors/header-interceptor.service';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedPipeModule } from './shared/shared-pipe/shared-pipe.module';
import { FooterModule } from './components/footer/footer.module';
import { RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { GlobalErrorHandler } from './pages/globalErrorHandler/globalErrorHandler';
import { GlobalErrorInterceptor } from './shared/interceptors/global-error-interceptor.service';
import { StatusInterceptor } from './shared/interceptors/status-interceptor.service';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: GlobalErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CreateDigitalInterceptorService,
    multi: true,
  },
  { provide: HTTP_INTERCEPTORS, useClass: StatusInterceptor, multi: true },
];

@NgModule({
  declarations: [AppComponent],
  providers: [
    httpInterceptorProviders,
    AuthService,
    VerifiedAsLoggedInService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6LdcXFIoAAAAAPtMaB5Lo-QhZsQjWaaaRRRNuS0c',
      } as RecaptchaSettings,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgIdleKeepaliveModule.forRoot(),
    HeaderModule,
    SharedPipeModule,
    FooterModule,
    RecaptchaModule,
  ],
})
export class AppModule { }
