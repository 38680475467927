import { UserService } from './user.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { RefreshService } from './refresh.service';

@Injectable()
export class VerifiedAsLoggedInService implements CanActivate {
  constructor(
    public router: Router,
    private cookieService: CookieService,
    private userService: UserService,
    private refreshService: RefreshService
  ) { }

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot, preventDefault?: any): Observable<boolean> {
    return new Observable((observer) => {
      if (!this.cookieService.get('authToken')) {
        if (preventDefault !== true) {
          //If the user came to subscribe deeplink send them to register instead of login
          if (state?.url?.indexOf('/subscribe') !== -1) {
            this.router.navigate(['/register'], { queryParams: { returnUrl: state.url } });
          } else {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
          }
        }
        observer.next(false);
      } else {
        if ((window as any).refreshedToken) return observer.next(true);

        this.refreshService.refreshAuthToken().subscribe({
          next: (latestTokenResponse: any) => {
            if (latestTokenResponse?.result?.accessToken) {
              this.cookieService.set(
                'authToken',
                latestTokenResponse.result.accessToken,
                5,
                '/',
                window.location.href.indexOf('examrevision.ie') !== -1 ? '.examrevision.ie' : undefined
              );
            }
            (window as any).refreshedToken = true;
            return observer.next(true);
          },
          error: () => {
            if (preventDefault !== true) {
              //check if cookie exists if so delete it
              if (this.cookieService.check('login'))
                this.cookieService.delete(
                  'login',
                  '/',
                  window.location.href.indexOf('examrevision.ie') !== -1 ? '.examrevision.ie' : undefined
                );
              this.cookieService.delete('login', '/');
              if (this.cookieService.check('authToken')) this.cookieService.delete('authToken', '/');
              this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            }
            return observer.next(false);
          },
        });
      }
    });
  }
}
