import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer-link',
  templateUrl: './footer-link.component.html',
  styleUrls: ['./footer-link.component.scss'],
})
export class FooterLinkComponent implements OnInit {
  public routerLinks: {
    link: string;
  }[] = [];

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.http
      .get(environment.createDigitalApiBaseUrl + '/websites/6038188a4b162471912ed976/pages?slug=data/links')
      .subscribe((linksPage: any) => {
        linksPage.result.populatedComponents.forEach((populatedComponent: { params: { link: string } }) => {
          this.routerLinks.push(populatedComponent.params);
        });
      });
  }
}
