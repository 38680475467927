import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractSubjectType',
})
export class ExtractSubjectTypePipe implements PipeTransform {
  transform(description: string): string {
    if (!description) {
      return '';
    }

    const matchResult = description.match(/Leaving Certificate|Leaving Cert|Junior Cycle/);

    // Check if the matchResult is found and not null
    if (matchResult && matchResult.length > 0) {
      return matchResult[0];
    } else {
      return '';
    }
  }
}
