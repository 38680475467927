import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { CookiePromptComponent } from './components/modals/cookie-prompt/cookie-prompt.component';
import { AuthService } from './shared/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private modalService: NgbModal,
    private cookieService: CookieService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.authService.setLoggedInStatus();
    const cookieConsent = this.cookieService.get('cookieConsent');

    if (!cookieConsent) {
      this.modalService.open(CookiePromptComponent);
    }
  }
}
