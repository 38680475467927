import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, retry, timer } from 'rxjs';

@Injectable()
export class GlobalErrorInterceptor implements HttpInterceptor {
  private readonly MAX_NUMBER_OF_RETRY_NO_CONNECTION: number = 2;
  private readonly RETRY_DELAY: number = 500; // in ms

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.checkUrl(request.url)) {
      if (request.method === 'GET') {
        return next.handle(request).pipe(
          retry({
            count: this.MAX_NUMBER_OF_RETRY_NO_CONNECTION + 1,
            delay: (error: HttpErrorResponse, retryAttempt: number): Observable<number> => {
              // if maximum number of retries have been met
              // or response is a status code we don't wish to retry, prompt user to reload page
              if (
                retryAttempt > this.MAX_NUMBER_OF_RETRY_NO_CONNECTION &&
                error.status !== 404 &&
                error.status !== 401
              ) {
                if (confirm('Something went wrong, please refresh page.')) {
                  window.location.reload();
                }
              }
              // retry after 1s, 2s, etc...
              return timer(retryAttempt * this.RETRY_DELAY);
            },
          }),
          catchError(() => {
            return next.handle(request);
          })
        );
      }
    }
    return next.handle(request);
  }

  private checkUrl(url: string) {
    const acceptedUrls = ['/api/', '/api2/'];
    const exemptedUrls = ['/api/v1/users/status', '/api2/user'];

    function checkIfAccepted(url: string) {
      for (const acceptedUrl of acceptedUrls) {
        if (url.startsWith(acceptedUrl)) return true;
      }

      return false;
    }

    function checkIfExempted(url: string) {
      for (const exemptedUrl of exemptedUrls) {
        if (url.startsWith(exemptedUrl)) return true;
      }

      return false;
    }

    return checkIfAccepted(url) && !checkIfExempted(url);
  }
}
