/**
 * The Component Module
 */
import { NgModule } from '@angular/core';
import { FooterComponent } from './footer.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReviewsButtonModule } from '../reviews-button/review-button.module';
import { FooterLinkModule } from '../footer-link/footer-link.module';

@NgModule({
  imports: [CommonModule, RouterModule, ReviewsButtonModule, FooterLinkModule],
  declarations: [FooterComponent],
  exports: [FooterComponent],
})
export class FooterModule {}
