import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SafeHtmlPipe } from './safe-html.pipe';
import { OrderByPipe } from './orderBy.pipe';
import { FilterPipe } from './filter.pipe';
import { SplitNameQualificationPipe } from './splitnamequalification.pipe';
import { ExtractSubjectTypePipe } from './extract-subject-type.pipe';
import { ExtractSubjectPipe } from './extract-subject.pipe';

@NgModule({
  declarations: [
    SafeHtmlPipe,
    OrderByPipe,
    FilterPipe,
    ExtractSubjectTypePipe,
    SplitNameQualificationPipe,
    ExtractSubjectPipe,
  ],
  imports: [CommonModule],
  exports: [
    SafeHtmlPipe,
    OrderByPipe,
    FilterPipe,
    DatePipe,
    ExtractSubjectTypePipe,
    SplitNameQualificationPipe,
    ExtractSubjectPipe,
  ],
})
export class SharedPipeModule {}
