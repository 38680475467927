<div class="margin-top-large">
  <div class="full-width subfooter">
    <div class="container footer-container">
      <div class="row pt-5 flex-column flex-md-row align-items-sm-flex-start footer gx-0 me-0">
        <div class="company-products logo col col-lg-2 pl-3 pr-3">
          <img src="assets/images/ExamRevisionFinalLogo.png" width="130px" alt="site logo" />
        </div>
        <div class="middle-items d-flex flex-wrap order-3 order-sm-3 order-lg-2">
          <div class="item company-products col col-lg-2.5 pl-3 pr-3 order-3 order-sm-3 order-lg-1">
            <b class="fw-bold">Product</b>
            <p>
              <a [routerLink]="'/'">Home</a>
            </p>
            <p>
              <a [routerLink]="'/subjects/junior-cycle'">Junior Cycle</a>
            </p>
            <p>
              <a [routerLink]="'/subjects/leaving-certificate'">Leaving Certificate</a>
            </p>
            <p>
              <a [routerLink]="'/revision-courses'">Live Courses</a>
            </p>
            <p>
              <a [routerLink]="'/pricing'">Pricing</a>
            </p>
            <p>
              <a [routerLink]="'/teachers'">Teachers & Schools</a>
            </p>
            <!-- <p>
              <a [routerLink]="'/teachers'">Free Grinds</a>
            </p> -->
          </div>
          <div class="item company-products col col-lg-2.5 order-3 order-sm-3 order-lg-2">
            <b class="fw-bold">Company</b>
            <p>
              <a [routerLink]="'/our-journey'">The ExamRevision Journey</a>
            </p>
            <p>
              <a [routerLink]="'/our-team'">Our Team</a>
            </p>
            <p>
              <a [routerLink]="'/careers'">Careers</a>
            </p>
            <p>
              <a [routerLink]="'/our-mission'">Our Mission</a>
            </p>
            <!-- <p>
              <a [routerLink]="'/exam-tips'">Exam Tips</a>
            </p> -->
            <p>
              <a href="https://examrevision.ie/sponsor-a-school">Sponsor a School</a>
            </p>
            <p>
              <a href="/assets/terms.pdf" target="_blank">Terms Of Service</a>
            </p>
            <p>
              <a href="/assets/privacy.pdf" target="_blank">Privacy Policy</a>
            </p>
          </div>

          <div class="item company-products col-12 col-lg-2 pl-3 pr-3 order-4 order-sm-4 order-lg-4">
            <b class="fw-bold">Support</b>
            <p>
              <a [routerLink]="'/faqs'">FAQ</a>
            </p>
            <p>
              <a [routerLink]="'/contact-us'">Contact Us</a>
            </p>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-3 row order-1 order-sm-1 order-lg-5 review-button-wrapper">
          <app-reviews-button (click)="openReviews()"></app-reviews-button>
          <div class="d-block d-lg-none copyright">
            <div class="menu">
              <div class="site-name d-block d-md-block d-lg-none mt-0 mt-sm-0">
                <span class="social">
                  <a href="https://www.youtube.com/channel/UClEqYMtdhlrEDffpbM_3LQw" target="_blank">
                    <i class="fab fa-youtube"></i>
                  </a>

                  <a href="https://twitter.com/examrevision4u" target="_blank">
                    <i class="fab fa-twitter"></i>
                  </a>
                  <a href="https://www.facebook.com/ExamRevision4u/" target="_blank">
                    <i class="fab fa-facebook"></i>
                  </a>
                  <a href="https://www.instagram.com/examrevision.ie/" target="_blank">
                    <i class="fab fa-instagram"></i>
                  </a>
                  <a class="tiktok-logo" href="https://www.tiktok.com/@examrevision.ie" target="_blank"
                    ><i class="fa-brands fa-tiktok"></i
                  ></a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright">
    <ul class="menu">
      <br />
      <!-- <app-footer-link></app-footer-link> -->
      <li class="small-font site-name">
        <span class="d-none d-md-none d-lg-block social mb-5">
          <a class="me-3" href="https://www.instagram.com/examrevision.ie/" target="_blank"
            ><i class="fab fa-instagram"></i
          ></a>
          <a class="me-3" href="https://www.youtube.com/channel/UClEqYMtdhlrEDffpbM_3LQw" target="_blank"
            ><i class="fab fa-youtube"></i
          ></a>
          <a class="me-3" href="https://twitter.com/examrevision4u" target="_blank"><i class="fab fa-twitter"></i></a>
          <a class="tiktok-logo me-3" href="https://www.tiktok.com/@examrevision.ie" target="_blank"
            ><i class="fa-brands fa-tiktok"></i
          ></a>
          <a href="https://www.facebook.com/ExamRevision4u/" target="_blank"><i class="fab fa-facebook"></i></a>
        </span>
        &copy; ExamRevision {{ date }}. All rights reserved.
      </li>
    </ul>
  </div>
</div>
