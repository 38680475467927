<div class="button-wrapper">
  <div class="reviews-button">
    <h6 class="bigger-text">Exam Revision</h6>
    <p class="star-wrapper">
      <span>
        {{ reviewsScore }}
        <i class="fas fa-star"></i>
        <i class="fas fa-star"></i>
        <i class="fas fa-star"></i>
        <i class="fas fa-star"></i>
        <i class="fas fa-star"></i>
      </span>
      <span class="num-reviews">{{ reviewsCount }} reviews</span>
    </p>
    <button class="btn btn-secondary btn-light" (click)="openReviews()">Leave a Review</button>
  </div>
</div>
