import { Injectable } from '@angular/core';
import { Review } from '../../shared/types/review';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReviewsService {
  private cachedReviews: Review[] | null = null;

  constructor(private http: HttpClient) {}

  getReviews(): Observable<Review[]> {
    if (this.cachedReviews) {
      return of(this.cachedReviews);
    }
    return this.http.get<Review[]>(`${environment.examRevisionApiBaseUrl}/api/v1/cms/reviews`).pipe(
      map((reviews) => {
        this.cachedReviews = reviews;
        return reviews;
      })
    );
  }

  getReviewsCount(): Observable<number> {
    return this.getReviews().pipe(map((reviews) => reviews.length + 100));
  }

  getReviewsScore(): Observable<number> {
    return this.getReviews().pipe(
      map((reviews) => {
        const values: number[] = reviews.map((review) => review.starCount);
        let score = this.weightedAverage(values);
        score = score < 4.25 ? 4.25 : score;
        // return Number(score.toFixed(2));
        // Client requested hardcoding to 4.97
        return 4.97;
      })
    );
  }

  private weightedAverage(nums: number[]): number {
    const weightedSum = [0, 0, 0, 0, 0];
    const weights: number[] = [];

    nums.forEach((num) => {
      switch (num) {
        case 1: {
          weightedSum[0] += num * 0.1;
          weights.push(0.1);
          break;
        }
        case 2: {
          weightedSum[1] += num * 0.1;
          weights.push(0.1);
          break;
        }
        case 3: {
          weightedSum[2] += num * 0.2;
          weights.push(0.2);
          break;
        }
        case 4: {
          weightedSum[3] += num * 0.25;
          weights.push(0.25);
          break;
        }
        case 5: {
          weightedSum[4] += num * 0.35;
          weights.push(0.35);
          break;
        }
        default: {
          break;
        }
      }
    });

    return weightedSum.reduce((a, b) => a + b, 0) / weights.reduce((a, b) => a + b, 0);
  }
}
