// search filter pipe
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {
  /**
   * Pipe filters the list of elements based on the search text provided
   *
   * @param items list of elements to search in
   * @param searchText search string
   * @returns list of elements filtered by search text or []
   */
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }

    if (this.isNumeric(searchText)) {
      return items.filter((item) => Object.keys(item).some((key) => item[key] == Number(searchText)));
    }

    return items.filter((item) =>
      Object.keys(item).some(
        (key) => typeof item[key] === 'string' && item[key].toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }

  public isNumeric(num: any) {
    return !isNaN(num);
  }
}
