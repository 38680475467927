import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractSubject',
})
export class ExtractSubjectPipe implements PipeTransform {
  transform(description: string): string {
    if (!description) {
      return '';
    }

    // Use a regular expression to capture the subject name
    const matchResult = description.match(/(Leaving Certificate|Junior Cycle)\s+(.+)/);

    if (matchResult && matchResult.length > 2) {
      return matchResult[2]; // Return the captured subject name
    } else {
      return description;
    }
  }
}
