/**
 * The Component Module
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReviewsButtonComponent } from './reviews-button.component';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [ReviewsButtonComponent],
  exports: [ReviewsButtonComponent],
})
export class ReviewsButtonModule {}
