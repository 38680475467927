import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class StatusInterceptor implements HttpInterceptor {
  constructor(private cookieService: CookieService, private http: HttpClient, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (this.checkUrl(error.url)) {
          if (error.status === 401) {
            const wasLoggedIn = this.cookieService.check('login') || this.cookieService.check('authToken');
            this.cookieService.delete(
              'login',
              '/',
              window.location.href.indexOf('examrevision.ie') !== -1 ? '.examrevision.ie' : undefined
            );
            this.cookieService.delete(
              'authToken',
              '/',
              window.location.href.indexOf('examrevision.ie') !== -1 ? '.examrevision.ie' : undefined
            );
            this.cookieService.delete('login', '/');
            this.cookieService.delete('authToken', '/');
            if (wasLoggedIn) {
              this.router.navigate(['/already-logged-in']);
            }
          }
        }
        return throwError(() => error);
      })
    );
  }

  private checkUrl(url: string) {
    const acceptedPatterns = [`api2`, 'localhost:3000'];

    for (const pattern of acceptedPatterns) {
      if (new RegExp(pattern).test(url)) return true;
    }

    return false;
  }
}
