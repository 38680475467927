import { CookieService } from 'ngx-cookie-service';
import { tap, BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public readonly loggedInStatus = new BehaviorSubject(
    !!(this.cookieService.get('login') || this.cookieService.get('authToken'))
  );

  constructor(private http: HttpClient, private cookieService: CookieService) {}

  login(emailAddress: string, password: string) {
    const regexEmail = emailAddress.replace(/['‘’‛❛❜＇′’`‘]/g, "'");

    this.logout(true);

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.http
      .post(
        `${environment.examRevisionApiBaseUrl}/api/v1/users/authenticate`,
        { emailAddress: regexEmail, password },
        httpOptions
      )
      .pipe(
        tap((userTokens) => {
          (userTokens as { accessToken: string; apiSource: string }[]).forEach(
            (accessToken: { accessToken: string; apiSource: string }) => {
              accessToken.apiSource === 'CMS'
                ? this.cookieService.set(
                    'login',
                    accessToken.accessToken,
                    5,
                    '/',
                    window.location.href.indexOf('examrevision.ie') !== -1 ? '.examrevision.ie' : undefined
                  )
                : this.cookieService.set(
                    'authToken',
                    accessToken.accessToken,
                    5,
                    '/',
                    window.location.href.indexOf('examrevision.ie') !== -1 ? '.examrevision.ie' : undefined
                  );
            },
            (error: any) => {
              if (error.status === HttpStatusCode.BadRequest) {
                console.error(error.error.message ?? 'Invalid email or password. Please try again.');
              } else {
                console.error('An error occurred during login. Please try again later.');
              }
            }
          );
          this.setLoggedInStatus();
        })
      );
  }

  logout(stopGlobalStatusUpdate?: boolean) {
    this.cookieService.delete(
      'login',
      '/',
      window.location.href.indexOf('examrevision.ie') !== -1 ? '.examrevision.ie' : undefined
    );
    this.cookieService.delete(
      'authToken',
      '/',
      window.location.href.indexOf('examrevision.ie') !== -1 ? '.examrevision.ie' : undefined
    );
    this.cookieService.delete('login', '/');
    this.cookieService.delete('authToken', '/');
    if (!stopGlobalStatusUpdate) {
      this.setLoggedInStatus();
    }
    sessionStorage.clear();
  }

  setLoggedInStatus() {
    this.loggedInStatus.next(!!(this.cookieService.get('login') || this.cookieService.get('authToken')));
  }
}
