import { ErrorHandler, Injectable } from '@angular/core';
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      if (confirm('Updates available. Please refresh page.')) {
        this.hardReload(window.location.href);
      }
    }
  }

  async hardReload(url: string) {
    await fetch(url, {
      method: 'POST',
      headers: {
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
      },
      cache: 'reload',
    });

    window.location.href = url;
    window.location.reload();
  }
}
