import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitNameQualification',
})
export class SplitNameQualificationPipe implements PipeTransform {
  transform(input: string, separator: string = '-'): { namePart: string; qualificationPart: string } {
    if (!input) {
      return { namePart: '', qualificationPart: '' };
    }

    const parts = input.split(separator).map((part) => part.trim());
    return { namePart: parts[0], qualificationPart: parts[1] };
  }
}
