import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RefreshService {
  constructor(private http: HttpClient) { }

  refreshAuthToken() {
    return this.http.post(`${environment.examRevisionApiBaseUrlV2}/user/authenticate/refresh`, {});
  }
}
