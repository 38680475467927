import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Keepalive } from '@ng-idle/keepalive';
import { Idle } from '@ng-idle/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private cookieService: CookieService,
    private keepalive: Keepalive,
    private http: HttpClient,
    private idle: Idle,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.checkPattern(request.url)) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          'x-access-token': this.cookieService.get('authToken'),
        },
      });

      if (this.cookieService.get('authToken')) {
        this.setupUserStatusPing();
      }
    }

    return next.handle(request);
  }

  private checkPattern(url: string) {
    const acceptedPatterns = ['api', 'api2', 'legacybe-staging', 'be-staging', 'localhost:3000', 'localhost:3001'];

    for (const pattern of acceptedPatterns) {
      if (new RegExp(pattern).test(url)) return true;
    }

    return false;
  }

  private setupUserStatusPing() {
    if (!this.keepalive.isRunning()) {
      this.keepalive.interval(60);
      this.keepalive.onPing.subscribe(
        () => {
          this.http.get(`${environment.examRevisionApiBaseUrlV2}/user/status`, {}).subscribe();
        },
        (error) => {
          this.keepalive.stop();
          this.keepalive.ngOnDestroy();
          console.error(error);
        }
      );
      this.idle.watch();
    }
  }
}
