import { Component, AfterViewInit, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cookie-prompt',
  templateUrl: './cookie-prompt.component.html',
  styleUrls: ['./cookie-prompt.component.scss'],
})
export class CookiePromptComponent implements AfterViewInit {
  constructor(private activeModal: NgbActiveModal, private cookieService: CookieService, private elRef: ElementRef) {}

  ngAfterViewInit() {
    const okButton = this.elRef.nativeElement.querySelector('.btn-primary');
    okButton.focus();
  }

  acceptCookies() {
    this.cookieService.set('cookieConsent', 'true');
    this.activeModal.close();
  }
}
