<div class="overlay" [ngClass]="{ 'show-overlay': MenuOpen }"></div>

<nav class="nav" [ngClass]="isLoggedIn ? '' : 'p-xxl-2'">
  <ul class="nav-list">
    <li class="nav-list-logo me-auto">
      <img class="logo" routerLink="/" [src]="'assets/images/ExamRevisionFinalLogo.png'" alt="Exam Revision Logo" />
    </li>
    <div
      [ngClass]="{
        open: MenuOpen,
        close: !MenuOpen && menuInteractionStarted
      }"
      (click)="toggleMenu()"
      class="burger-menu"
      class="menu-btn d-flex d-md-flex d-lg-flex d-xl-flex d-xxl-none"
    >
      <div class="menu-btn__burger"></div>
    </div>
    <div class="nav-list-item-wrapper d-none d-md-none d-lg-none d-xl-none d-xxl-flex header-nav-wrapper">
      <li
        [ngStyle]="{ display: isRegisterOrLogin ? 'none' : '' }"
        class="nav-list-item"
        *ngFor="let header of dropdownInUse?.header"
      >
        <a [routerLink]="header.link" [ngClass]="{'isActivePage': header.isActivePage}">{{ header.name }}</a>
        <ul class="nav-list-item-drop" *ngIf="header.subheader?.length">
          <div class="d-flex flex-row">
            <!-- Inside the loop for subheader -->
            <li *ngFor="let subheader of header.subheader" class="subheader-item">
              <div class="d-flex flex-column">
                <a class="burger-menu-item mb-2 no-hover-effect" [routerLink]="subheader.link" (click)="burgerMenuOpen = false">
                  <strong> {{ subheader.name }}</strong></a
                >
                <a
                  class="burger-menu-item mb-2 smallest-font no-hover-effect"
                  [routerLink]="subheader.link"
                  (click)="burgerMenuOpen = false"
                >
                  <strong>{{ subheader.subname }}</strong></a
                >
                <a
                  class="burger-menu-item smallest-font no-hover-effect"
                  [routerLink]="subheader.link"
                  (click)="burgerMenuOpen = false"
                ><span class="subheader-paragraph">{{ subheader.paragraph }}</span></a>
              </div>
            </li>
          </div>
        </ul>
      </li>
    </div>
    <div class="button-container">
      <div
        class="button-wrapper flex-column flex-xxl-row justify-content-center align-items-center d-none d-md-none d-lg-none d-xl-none d-xxl-flex"
      >
        <a class="haveAccount" [routerLink]="isLogin ? '/register' : '/login'" [queryParams]="switchPageParams" *ngIf="isRegisterOrLogin">{{
          isLogin ? 'Need to register instead?' : 'Already have an account?'
        }}</a>
        <button *ngIf="!isLoggedIn" class="button btn-tertiary" [routerLink]="isLogin ? '/register' : '/login'"  [queryParams]="switchPageParams">
          {{ isLogin ? 'Sign up for free' : 'Login' }}
        </button>
        <button *ngIf="!isRegisterOrLogin && !isLoggedIn" class="button" [routerLink]="'/register'"  [queryParams]="switchPageParams">
          Sign up for free
        </button>
        <button *ngIf="isLoggedIn" class="button btn-tertiary" [routerLink]="'/contact-us'">Contact us</button>
      </div>
    </div>
  </ul>
</nav>

<div
  [ngClass]="{
    open: MenuOpen,
    'open-menu': burgerMenuOpen,
    'close-menu': !burgerMenuOpen && menuInteractionStarted,
    close: !MenuOpen && menuInteractionStarted
  }"
  class="burger-menu"
>
  <li
    [ngStyle]="{ display: isRegisterOrLogin ? 'none' : '' }"
    class="py-3"
    *ngFor="let header of dropdownInUse?.header; let i = index"
  >
    <ul class="menu" (click)="show(i)">
      <a class="burger-menu-item" [routerLink]="header.link" (click)="closeBurger(header); burgerMenuOpen = false; this.MenuOpen = false">{{
        header.name
      }}</a>
      <i *ngIf="header.subheader" class="fa fa-angle-down dropdown-icon" aria-hidden="true"></i>
    </ul>
    <ul class="submenu" *ngIf="header.show">
      <li *ngFor="let subheader of header.subheader">
        <a class="burger-menu-item" [routerLink]="subheader.link" (click)="closeBurger(header); burgerMenuOpen = false; this.MenuOpen = false">{{
          subheader.name
        }}</a>
      </li>
    </ul>
  </li>
  <div
    class="button-wrapper flex-column flex-md-column flex-lg-column flex-xxl-row justify-content-center align-items-center d-xxl-flex"
  >
    <a (click)="toggleMenu()" class="haveAccount w-100" *ngIf="isRegisterOrLogin">{{
      isLogin ? 'Need to register instead?' : 'Already have an account?'
    }}</a>
    <button
      (click)="toggleMenu()"
      *ngIf="!isLoggedIn"
      class="button btn-tertiary w-100"
      [routerLink]="isLogin ? '/register' : '/login'"
      [queryParams]="switchPageParams"
    >
      {{ isLogin ? 'Sign up for free' : 'Login' }}
    </button>
    <button
      (click)="toggleMenu()"
      *ngIf="!isRegisterOrLogin && !isLoggedIn"
      class="button w-100"
      [routerLink]="'/register'"
      [queryParams]="switchPageParams"
    >
      Sign up for free
    </button>
    <button *ngIf="isLoggedIn" class="button btn-tertiary w-100" [routerLink]="'/contact-us'">Contact us</button>
  </div>
</div>
