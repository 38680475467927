/**
 * The Component Module
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FooterLinkComponent } from './footer-link.component';
import { ReviewsButtonModule } from '../reviews-button/review-button.module';

@NgModule({
  imports: [CommonModule, RouterModule, ReviewsButtonModule],
  declarations: [FooterLinkComponent],
  exports: [FooterLinkComponent],
})
export class FooterLinkModule {}
