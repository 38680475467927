import { Observable, switchMap } from 'rxjs';
import { User } from './../types/user.d';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  getUserByEmail(email: string): Observable<{ result: User }> {
    // Adjust the API endpoint and add the email as a query parameter
    const apiUrl = `${environment.examRevisionApiBaseUrlV2}/user/email/email?email=${email}`;

    return this.http.get<{ result: User }>(apiUrl);
  }

  getCurrentUser(): Observable<{
    result: User;
  }> {
    return this.http.get<{ result: User }>(`${environment.examRevisionApiBaseUrlV2}/user`);
  }

  updatePortalSubjects(selectedSubjects: string[]): Observable<any> {
    return this.getCurrentUser().pipe(
      switchMap((user: any): any => {
        const userResult = user?.result;
        return this.http.put(`${environment.examRevisionApiBaseUrlV2}/user/${userResult?._id}`, { selectedSubjects });
      })
    );
  }

  updateGoals(goal: string): Observable<any> {
    return this.getCurrentUser().pipe(
      switchMap((user: any): any => {
        const userResult = user?.result;
        return this.http.put(`${environment.examRevisionApiBaseUrlV2}/user/${userResult?._id}`, { goal });
      })
    );
  }

  updateWelcomeModalTriggered(subjectsTriggered: string[]): Observable<any> {
    return this.getCurrentUser().pipe(
      switchMap((user: any): any => {
        const userResult = user?.result;
        const currentWelcomeModalTriggered = userResult?.welcomeModalTriggered || [];
        const updatedWelcomeModalTriggered = [...currentWelcomeModalTriggered, subjectsTriggered];

        return this.http.put(`${environment.examRevisionApiBaseUrlV2}/user/` + userResult?._id, {
          welcomeModalTriggered: updatedWelcomeModalTriggered,
        });
      })
    );
  }
}
